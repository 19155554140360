import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom';

import { useEffect, Suspense, lazy } from 'react'

// Dummy Data
import { viewsNav } from './data/viewsNav'
import { modulesNav } from './data/modulesNav'
import { configNav } from './data/configNav'

import Auth from './components/auth/Auth';
import Nav from './components/Nav';
import Content from './components/Content';
import Permissions from './components/auth/Permissions';

import { getAWSCredentials } from './api/accounts'
import { getByUsername } from './api/users'
import { getAllPermissions } from './api/permissions';
import { logout } from './util/logout'

const Login = lazy(() => import('./pages/account/Login'));

const Dashboard = lazy(() => import('./pages/Dashboard'))

// // Views Pages
const Events = lazy(() => import('./pages/views/Events'))
const LiveView = lazy(() => import('./pages/views/LiveView'))

// const LiveTest = lazy(() => import('./pages/views/LiveTest'))

const Wall = lazy(() => import('./pages/views/Wall'))
const LiveWall = lazy(() => import('./pages/views/LiveWall'))
const AddWall = lazy(() => import('./pages/views/AddWall'))
const Play = lazy(() => import('./pages/views/Play'))
const GuestPlayer = lazy(() => import('./pages/views/GuestPlayer'))
// const Play24Hour = lazy(() => import('./pages/views/Play24Hour'))
const TwoFour = lazy(() => import('./pages/views/TwoFour'))
const People = lazy(() => import('./pages/views/People'))
const PeopleSingle = lazy(() => import('./pages/views/PeopleSingle'))

const Vault = lazy(() => import('./pages/views/Vault'))
const VaultTags = lazy(() => import('./pages/config/VaultTags'))
const VaultEvent = lazy(() => import('./pages/views/VaultEvent'))

// // Module Pages
const Alerts = lazy(() => import('./pages/modules/Alerts'))
const AddAlert = lazy(() => import('./pages/modules/AddAlert'))
const Detections = lazy(() => import('./pages/modules/Detections'))

// // Config Pages
const Cameras = lazy(() => import('./pages/config/Cameras'))
const EditCamera = lazy(() => import('./pages/config/EditCamera'))
const Locations = lazy(() => import('./pages/config/Locations'))
const Systems = lazy(() => import('./pages/config/Systems'))
const Tags = lazy(() => import('./pages/config/Tags'))
const UsersPage = lazy(() => import('./pages/config/UsersPage'))




const Share = lazy(() => import('./pages/Share'))


// Account Pages
const Account = lazy(() => import('./pages/account/Account'))
const LicenseAgreement = lazy(() => import('./pages/account/LicenseAgreement'))

function App() {
  
  useEffect(() => {
    let isLoggedIn = localStorage.getItem('auth')

    if(isLoggedIn != null){
      getPermissions()
    } 
  }, [])

  function getPermissions(){
    getAllPermissions().then((d) => {
    	console.log("jake permissions raw", d)
        console.log("jakes permissions", d.data.userPermissions)
        localStorage.setItem('userPermissions', JSON.stringify(d.data.userPermissions))
    }).catch(function (err) {
      console.log(err)
    })

    let u = localStorage.getItem('username');
    console.log(u);
    if (u) {
      getByUsername(u).then((d) => {
        console.log(d)
        localStorage.setItem('permissions', JSON.stringify(d.data.user_row.permissions))
        localStorage.setItem('company_id', JSON.stringify(d.data.user_row.company_id))
        localStorage.setItem('timezone', JSON.stringify(d.data.user_row.timezone))
      }).catch(function (err) {
        console.log(err)
        // logout()
      })
    }

    getAWSCredentials().then((d) => {
      if(d.status === 200){
        localStorage.setItem('aws-accessKey', d.data.accessKey)
        localStorage.setItem('aws-secret', d.data.secret)
        localStorage.setItem('aws-token', d.data.token)
      } else {
        console.log("error")
      }
    }).catch(function (err) {
      // if(err.response.status === 401){
      // logout()
      // }
    })

    console.log("looking for permissions")
  }

  return ( 
    <BrowserRouter>
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route exact path="/" render={() => (
            <Login />
          )}/>

          <Route exact path="/login" render={() => (
            <Login />
          )}/>

          <Route exact path="/shared/:token" render={({match}) => (
            <GuestPlayer 
              token={match.params.token}
            />
          )}/>

          <Route exact path="/dashboard" render={() => ( 
            <Dashboard />
          )}/>

          {/*<Route exact path="/people" render={() => (
            <Auth>
              <div>
                <Nav 
                  subnav={viewsNav}
                />
                <Content>
                  <People />
                </Content>
              </div>
            </Auth>
          )}/>

          <Route exact path="/people/:id" render={() => (
            <Auth>
              <div>
                <Nav 
                  subnav={viewsNav}
                />
                <Content>
                  <PeopleSingle />
                </Content>
              </div>
            </Auth>
          )}/>*/}

          <Route exact path="/24-hour" render={() => (
            <Auth>
              <Permissions index={6} value={"can_view"} redirect={true}>
                <Nav 
                  subnav={viewsNav}
                />
                <Content>
                  <TwoFour />
                </Content>
              </Permissions>
            </Auth>
          )}/>   

          <Route exact path="/events" render={() => (
            <Auth>
              <Permissions index={6} value={"can_view"} redirect={true}>
                <Nav 
                  subnav={viewsNav}
                />
                <Content>
                  <Events />
                </Content>
              </Permissions>
            </Auth>
          )}/>

          <Route exact path="/vault" render={() => (
            <Auth>
              <Permissions index={8} value={"can_view"} redirect={true}>
                <Nav 
                  subnav={viewsNav}
                />
                <Content>
                  <Vault />
                </Content>
              </Permissions>
            </Auth>
          )}/>

          <Route exact path="/vault/:id" render={({match}) => (
            <Auth>
              <Permissions index={8} value={"can_view"} redirect={true}>
                <Nav 
                  subnav={viewsNav}
                />
                
                <VaultEvent 
                  id={match.params.id}
                />
              </Permissions>
            </Auth>
          )}/>

          <Route exact path="/vault-tags" render={() => (
            <Auth>
              <Permissions index={8} value={"can_edit"} redirect={false}>
                <Nav 
                  subnav={configNav}
                />
                <Content>
                  <VaultTags />
                </Content>
              </Permissions>
            </Auth>
          )}/>

          <Route exact path="/event/:camera/:start/:end" render={({match}) => (
            <Auth>
              <Permissions index={6} value={"can_view"} redirect={true}>
                <Nav 
                  subnav={viewsNav}
                />
                <Content>
                  <Play 
                    camera={match.params.camera}
                    start={match.params.start}
                    end={match.params.end}
                  />
                </Content>
              </Permissions>
            </Auth>
          )}/>


          <Route exact path="/event/:camera/:start/" render={({match}) => (
            <Auth>
              <Permissions index={6} value={"can_view"} redirect={true}>
                <Nav 
                  subnav={viewsNav}
                />
                <Content>
                  <Play 
                    camera={match.params.camera}
                    start={match.params.start}
                  />
                </Content>
              </Permissions>
            </Auth>
          )}/>
          
          {/*<Route exact path="/live-view" render={() => (
            <Auth>
              <Permissions index={7} value={"can_view"} redirect={true}>
                <Nav 
                  subnav={viewsNav}
                />
                <Content>
                  <LiveView />
                </Content>
              </Permissions>
            </Auth>
          )}/>

          <Route exact path="/wall" render={() => (
            <Auth>
              <Permissions index={7} value={"can_view"} redirect={true}>
                <Nav 
                  subnav={viewsNav}
                />
                <Content>
                  <Wall />
                </Content>
              </Permissions>
            </Auth>
          )}/>*/}

          <Route exact path="/live-view" render={() => (
            <Auth>
              <Permissions index={7} value={"can_view"} redirect={true}>
                <Nav 
                  subnav={viewsNav}
                />
                <Content>
                  <LiveWall />
                </Content>
              </Permissions>
            </Auth>
          )}/>

          <Route exact path="/live-view/add" render={() => (
            <Auth>
              <Permissions index={7} value={"can_edit"} redirect={true}>
                <Nav 
                  subnav={viewsNav}
                />
                <Content>
                  <AddWall />
                </Content>
              </Permissions>
            </Auth>
          )}/>

          <Route exact path="/live-view/:id" render={({ match }) => (
            <Auth>
              <Permissions index={7} value={"can_edit"} redirect={true}>
                <Nav 
                  subnav={viewsNav}
                />
                <Content>
                  <AddWall 
                    id={match.params.id}
                  />
                </Content>
              </Permissions>
            </Auth>
          )}/>

         

          <Route exact path="/alerts" render={() => (
            <Auth>
              
                <Nav 
                  subnav={modulesNav}
                />
                <Content>
                  <Permissions index={1} value={"can_view"} redirect={false}>
                    <Alerts />
                  </Permissions>
                </Content>
              
            </Auth>
          )}/>

          <Route exact path="/add-alert" render={() => (
            <Auth>
              <Permissions index={1} value={"can_add"} redirect={true}>
                <Nav 
                  subnav={modulesNav}
                />
                <Content>
                  <AddAlert />
                </Content>
              </Permissions>
            </Auth>
          )}/>

          <Route exact path="/edit-alert/:id" render={({ match }) => (
            <Auth>
              <Permissions index={1} value={"can_view"} redirect={true}>
                <Nav 
                  subnav={modulesNav}
                />
                <Content>
                  <AddAlert 
                    alertId={match.params.id}
                  />
                </Content>
              </Permissions>
            </Auth>
          )}/>

          <Route exact path="/detections" render={() => (
            <Auth>
              <Permissions index={11} value={"can_view"} redirect={true}>
                <Nav 
                  subnav={modulesNav}
                />
                <Content>
                  <Detections />
                </Content>
              </Permissions>
            </Auth>
          )}/>

          <Route exact path="/cameras" render={() => (
            <Auth>
              <Permissions index={5} value={"can_view"} redirect={true}>
                <Nav 
                  subnav={configNav}
                />
                <Content>
                  <Cameras />
                </Content>
              </Permissions>
            </Auth>
          )}/>
      
          <Route exact path="/camera/:id" render={() => (
            <Auth>
              <Permissions index={1} value={"can_view"} redirect={true}>
                <Nav 
                  subnav={configNav}
                />
                <Content>
                  <EditCamera />
                </Content>
              </Permissions>
            </Auth>
          )}/>
        
          <Route exact path="/tags" render={() => (
            <Auth>
              <Permissions index={12} value={"can_view"} redirect={false}>
                <Nav 
                  subnav={configNav}
                />
                <Content>
                  <Tags />
                </Content>
              </Permissions>
            </Auth>
          )}/>

          <Route exact path="/locations" render={() => (
            <Auth>
              <Permissions index={13} value={"can_view"} redirect={true}>
                <Nav 
                  subnav={configNav}
                />
                <Content>
                  <Locations />
                </Content>
              </Permissions>
            </Auth>
          )}/>

          <Route exact path="/systems" render={() => (
            <Auth>
              <Permissions index={14} value={"can_view"} redirect={true}>
                <Nav 
                  subnav={configNav}
                />
                <Content>
                  <Systems />
                </Content>
              </Permissions>
            </Auth>
          )}/>

          <Route exact path="/users" render={() => (
            <Auth>
              <Permissions index={4} value={"can_view"} redirect={true}>
                <Nav 
                  subnav={configNav}
                />
                <Content>
                  <UsersPage />
                </Content>
              </Permissions>
            </Auth>
          )}/>

          <Route exact path="/share" render={() => (
            <Auth>
              <Nav 
                
              />
              <Content>
                <Share />
              </Content>
            </Auth>
          )}/>

          <Route exact path="/account" render={() => (
            <Auth>
              <Nav />
              <div className="pt-3 block clearfix">
                <Account />
              </div>
            </Auth>
          )}/>

          <Route exact path="/account/eula" render={() => (
              <LicenseAgreement />
          )}/>

          <Route path="/account/eula" render={() => (
              <LicenseAgreement />
          )}/>

           <Route path="*" render={() => (
              <Login />
          )}/>

        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
