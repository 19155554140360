import React  from 'react';


export default function Menu(props) {
    return (
        <ul className={"menu " + props.position}>
            {props.data.map((d,i) => {
                return (
                    <li key={i} onClick={() => props.onClick(d,i)}>
                        {d.name}
                    </li>
                )
            })}
        </ul>
    )
}