import React, { useState } from 'react';
import { withRouter, Link } from "react-router-dom";

import NavTabs from './NavTabs';
import Menu from './Menu';
import Logo from './Logo';

import { nav } from './../data/nav'
import { accountNav } from './../data/accountNav'

import { logout } from './../util/logout'

function Nav(props) {
    const [visible, setVisible] = useState(false)

    function click(d, i){
      setVisible(false)
      if(d.pathname === "/login"){
        logout()
      } else {
        props.history.push(d.pathname)  
        setVisible(false)  
      }

    }


    function toggleNav() {
        setVisible(!visible)
    }

    return (
        <div className="nav-group">
            <div className="nav">
                <div className="nav-container">
                  <div className="distribute distribute-between">
                    <Logo 

                    />
                    <NavTabs
                      tabs={nav}
                    />

                    <div className="block relative">
                      <div className="hover distribute distribute-end" onClick={toggleNav}>
                        <svg className="user-toggle" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                        {(visible) ? 
                          <div className="block relative" style={{top: -4}}>
                            <Menu 
                              data={accountNav}
                              position="right"
                              onClick={click}
                            />
                          </div>
                        :''}
                      </div>
                      

                      {(visible) ? 
                        <div className="nav-overlay" onClick={toggleNav}></div>
                      :''}
                      
                    </div>
                  </div>
                </div>
            </div>

            <div className="mobile-nav">
                <div className="mobile-nav-container">
                  <div className="distribute distribute-between">
                    <Logo />
                    <svg className="hover" onClick={toggleNav} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
                  </div>
                </div>


                {(visible) ? 
                  <div className="mobile-nav-items">
                    <div className="pt-2 pr-2 pl-2">
                      <div className="distribute distribute-end">
                        <svg className="hover" onClick={toggleNav} xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                      </div>
                    </div>

                    <div className="pt-3 pr-2 pl-2">
                      <ul className="primary mb-6 clearfix">
                        {nav.map((d, i) => {
                          return(
                            <li className="hover" onClick={() => click(d, i)}>{d.name}</li>    
                          )
                        })}
                      </ul>

                      <ul className="secondary">
                        {accountNav.map((d, i) => {
                          return(
                            <li className="hover" onClick={() => click(d, i)}>{d.name}</li>    
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                : ""}
            </div>



            {(props.subnav != null) ? 
              <div className="sub-nav">
                <div className="sub-nav-container">
                  <div className="distribute distribute-start">
                    <NavTabs
                      tabs={props.subnav}
                    />
                  </div>
                </div>
              </div>
            : ''}
            
        </div>
    )
}


export default withRouter(Nav);