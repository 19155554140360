import { authorized } from './api';

export function getAWSCredentials() {
    return authorized().get('/user/getAWSSession')
}

export function getAccount() {
    return authorized().get('/user')
}

export function updateAccount(data) {
    return authorized().put('/user', data)
}


// super duper admin functions

export function getCompanies() {
    return authorized().get('/systemusers/getAllCompanies')
}

export function switchCompany(d) {
    return authorized().post('/systemusers/switch', d)
}
