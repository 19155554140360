export default function Permissions(props) {
    const permissions = JSON.parse(localStorage.getItem('userPermissions'))
    

    if(permissions){
        const feature = permissions.find(a => a.feature_id === props.index)
        
        if(permissions && feature){
            const hasPermission = feature[props.value] === 1
        
            
            if(hasPermission){
                return props.children
            } else {
                if(props.redirect){
                    window.location = "/login"
                } else {
                    return null 
                }
            return props.children
            }    
        } else {
            if(props.redirect){
                window.location = "/login"
            } else {
                return null 
            }
        }    
    }
    
    
}

