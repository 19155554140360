import { authorized } from './api';

export function getUsers() {
    return authorized().get('systemusers')
}

export function getByUsername(username) {
    return authorized().get('systemusers/'+ username)
}

export function createUser(user) {
    return authorized().post('systemusers', user)
}

export function updateUser(user) {
    return authorized().put('systemusers', user)
}

export function deleteUserByUsername(id) {
    return authorized().delete('systemusers/'+ id)
}

export function checkUsername(name) {
    return authorized().post('systemusers/check', {username: name})
}
