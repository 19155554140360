export default function Auth(props) {
    let hasAuth = localStorage.getItem('auth')
    let hasEULA = localStorage.getItem('hasEULA')

    console.log("hasEULA", hasEULA)

    if(hasAuth && hasEULA === "1"){
        console.log("condition 1")
        return (
            <div>
                {props.children}
            </div>
        )
    } else {
        if(hasEULA === "0" && hasAuth && window.location.pathname === "/account/eula"){
            return (
                <div>
                    {props.children}
                </div>
            )   
        } else if(hasAuth && window.location.pathname !== "/account/eula") {
            window.location = "/account/eula"
        } else {
            window.location = "/login"
        }
    }
}