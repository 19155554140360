import { NavLink } from "react-router-dom";
import Permissions from './auth/Permissions'

export default function NavTabs(props) {
    return ( 
        <ul className="tabs">
        	{props.tabs.map((d,i) => {
                return(
                	<Permissions index={d.index} value={"can_view"}>
	                    <li key={i}>
	                        <NavLink 
	                        	activeClassName={"active"} key={i}
	                        	to={d.pathname}
	                        >{d.name}</NavLink>
	                    </li>
	                </Permissions>
                )
            })}
        </ul>
    )
}